module services {
    export module applicationcore {
        export class repositoryService implements interfaces.applicationcore.IRepositoryService {
            static $inject = ["generalService", "ENV", "$http", "$timeout","$resource"];

            constructor(private generalService: interfaces.applicationcore.IGeneralService, private ENV: interfaces.applicationcore.serverConfig,
                private $http: ng.IHttpService, private $timeout: ng.ITimeoutService,private $resource: ng.resource.IResourceService) {
            }

            EmailFiles(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Repository/EmailFiles");
            }

            downloadRepositoryFiles(fileKeys: interfaces.applicationcore.FileKeys) {
                
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

               return this.$http({
                    method: 'POST',
                    responseType: 'arraybuffer',
                    url: this.ENV.DSP_URL + 'Repository/GetRepositoryFiles',
                    data: fileKeys
                }).then((response) => {
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });
                    var filename = response.headers()['x-filename'] || 'download.bin';

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            downloadRepositoryFile(key: string, IsViewable?: boolean) {
                if (!IsViewable)
                {
                    this.generalService.displayMessage("You are unable to view the document!", Enum.EnumMessageType.Error);
                    return;
                }

                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: this.ENV.DSP_URL + 'Repository/GetRepositoryFile?key=' + key
                }).then((response) => {
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });
                    var filename = response.headers()['x-filename'] || 'download.bin';

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }
        }
    }
    angular.module("app").service("repositoryService", services.applicationcore.repositoryService);
}